$colorPrincipal : #F5F4F4;
$colorSec: #000000;
// #4e4a4a

// $colorPrincipal : #000000;
// $colorSec: #F5F4F4;


.invitation
{
    height: 100%;

    background-color: $colorSec;

    .qrdefault{
        max-height: 250px;
    }
    
    p{
        color:$colorPrincipal;
    }

    b{
        color:$colorPrincipal;
    }

    .line-black {
        border-top: 2px solid $colorPrincipal;
    }
    
    .fa-download
    {
        color:$colorPrincipal;
    }

    .fa-spinner
    {
        color:$colorPrincipal;
    }

    button{
        color:$colorPrincipal;
    }
}

