/**
*   inputs.scss
*   @description: Estilos para los inputs
*/

input, select, option, textarea {
    font-family: $Mukta;
}

select:disabled {
    background-color: #EBEBE4;
}

.input {
    color: $dark;
    padding: $paddingNormal;
    border-bottom: 2px solid rgba($gray, 0.5);
    &:focus { border-bottom: 2px solid $primary; }
}

.input-select {
    border-radius: 4px;
    padding: $paddingNormal;
    font-weight: $weightSemi;
}

.incidents .input-select, .guests .input-select {
    width: 200px;
    margin-left: $marginMedium;
}

.textarea {
    resize: none;
    line-height: 24px;
    padding: $paddingNormal;
    background-color: #f2f2f3;
}

.radio {    
    top: 0;
    border: 0;
    height: 20px;
    width: 20px;
    outline: none;
    cursor: pointer;     
    border-radius: 50px;
    color: #666666;
    background-color: #f1f1f1;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    margin-right: $marginMedium;
    transition: all 300ms ease-in-out;
}
.radio:checked::before {
    left: 6px;
    font-size: 0.8rem;
    content: '\02143';
    position: absolute;
    transform: rotate(40deg);
}

.radio:hover { background-color: #dedede; }

.radio:checked {
    color: $white;
    background-color: $primary;
}

.radio-label {
    color: $dark;
    cursor: pointer;
    font-family: $Mukta;
    font-size: $fontSmall;
    margin-right: $marginMedium;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.input-modals {
    width: 100%;
    padding: 4px 2px;
}

.input-modals-small {
    width: 40%;
    padding: 4px 2px;
}

.modal-incidents #incident-resolution {
    border-radius: 0;
    background-color: #f2f2f3;
}

.modal-houses .input {
    width: 100%;
    margin-left: $marginNormal;
}

