/**
*   button.scss
*   @description: Estilos para los botones
*/

button {
    cursor: pointer;
    font-family: $Mukta;
    background-color: transparent;
    &:disabled {
        cursor: not-allowed;
    }
}

.btn {
    cursor: pointer;
    font-family: $Mukta;
    font-size: $fontSmall;
    font-weight: $weightSemi;
    background-color: transparent;
    padding: $paddingMedium $paddingSemi;
    transition: all 200ms ease-in-out;
    &:active { transform: scale(0.95); }
}


.btn-mini {
    padding: 2px 7px;
    font-size: .8rem;
}

.btn-medium {
    width: 50%;
}

.btn-full{
    width: 100% !important;
}

.btn-primary {
    background-color: $pink;
    &:hover { background-color: rgba($primary, 0.8); }
}

.btn-secondary {
    background-color: $secondary;
    &:hover { background-color: rgba($secondary, 0.8); }
}

.btn-small {
    padding: $paddingNormal $paddingMedium;
}